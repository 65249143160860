function initMiewVisitas() {
    if ($('.slick-visitas').length > 0) {
        $('.slick-visitas').slick({
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            infinite: false,
            adaptiveHeight: false,
            autoplaySpeed: 8000,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }
}
