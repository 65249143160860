$(window).on('load', function () {
  // $('.main-header').remove();

  let menuTimer;
  
  // initMenuNav();

  if ($('#menu-header-bg > div.visible').length > 0) {
    TweenLite.set($('#menu-header-bg > div.visible'), {
      autoAlpha: 1,
      zIndex: 998,
    });
  }
  $('#menu-header-menu .menu-item a').on('mouseenter', function () {
    clearTimeout(menuTimer);
    const getImg = $(this).parent().index();
    const $oldEl = $('#menu-header-bg > div.visible');
    const $newEl = $('#menu-header-bg > div:eq(' + getImg + ')');

    if ($oldEl.length > 0) {
      TweenLite.set($oldEl, {
        autoAlpha: 0,
        zIndex: 1
      });
    }

    TweenLite.to($newEl, .35, {
      autoAlpha: 1,
      zIndex: 998,
      ease: Power2.easeOut,
    });

    $oldEl.removeClass('visible');
    $newEl.addClass('visible');
  });
  $('#menu-header-menu .menu-item a').on('mouseleave', function () {
    menuTimer = setTimeout(function () {
      const $oldEl = $('#menu-header-bg > div.visible');
      const $newEl = $('#menu-header-bg > div.current');

      if ($oldEl.length > 0) {
        TweenLite.to($oldEl, .35, {
          autoAlpha: 0,
          zIndex: 1,
          ease: Power2.easeOut,
        });
        $oldEl.removeClass('visible');
      }


      if ($newEl.length > 0) {
        TweenLite.to($newEl, .35, {
          autoAlpha: 1,
          zIndex: 998,
          ease: Power2.easeOut,
        });
        $newEl.addClass('visible');
      }
    }, 500);
  });

  $(window).resize(function () {
    if (!$('#masthead').hasClass('menu-absolute')) {
      const alt_menu = $('#masthead > div').outerHeight();
      $('.menu-wrapper').height(alt_menu);
    }
    if ($('body').innerWidth() > 960) {
      const alt_footer = $('.site-footer').outerHeight();
      $('.page-wrapper').css('margin-bottom', alt_footer);
    }
  });
});

function customScripts() {
  /*HELPERS */
  const templateUrl = phpVars.stylesheet_directory_uri;

  initMiewPosts();

  if ($('body').hasClass('single-product') && $('.main-image').length > 0) {
    $('.product-detail-back').prependTo('.product_intro .column_parent:eq(0) .column_child:eq(0) .uncont');
    $('.main-image > div').attr({
      'data-parallax': '{"axis": "y", "fixedTil": ".product-history", "parent":false}'
    });
    $('.detail-text:eq(0)').attr({
      'data-parallax': '{"axis":"x", "centered": true}',
      'data-parallax-mobile': 'true',
    });
    $('.detail-text:eq(1)').attr({
      'data-parallax': '{"axis":"x", "centered": true, "direction":"right"}',
      'data-parallax-mobile': 'true',
    });

    initCompare();
  }

  if ($('.produtos-list').length > 0) {    
    $('.filter-opener').attr({
      'data-parallax': '{"axis": "y", "fixedTil": ".produtos-list", "parent":false}',
      'data-parallax-mobile': 'true',
    });
    // $('.sidebar-products').attr({
    //     'data-parallax': '{"axis": "y", "fixedTil": ".produtos-list", "parent":false}',
    //     'data-parallax-mobile': 'true',
    // });
    $('.filter-opener').on('click.sidebarOpener', function () {
      $('body').toggleClass('filters-opened overHidden-filters');
      $('.footer-scroll-top').toggleClass('hidden');
    });
  
    
    $('body').on('click', function (e) {
      'use strict';
      if ($(e.target).closest('.sidebar-products').length == 0 && $(e.target).closest('.filter-opener').length == 0) {
        if ($('body').hasClass('filters-opened')) $('body').removeClass('filters-opened overHidden-filters');
      }
    });
  }

  if ($('.section_title ').length > 0) {
    $('.section_title').attr({
      'data-parallax': '{"axis": "y", "fixedTil": "parent:.vc_row", "parent":false, "offset": 240}',
    });
  }
  if ($('.history-section').length > 0) {
    $('.history-boat').attr({
      'data-fromto': '-50%,-200%',
    });
    $('.history-year').attr({
      'data-fromto': '25%,-150%',
    });
  }
    
  products_isotope();
  catalogIntro();
  sideScroll();
  initParallaxElements();

  window.dispatchEvent(new Event('resize'));
}
function products_isotope() {
  if ($('.produtos-list').length > 0) {
    if ($('.sidebar-products .append-filters').length > 0) {
      $('.momments-filter-container').html('')
      $('.sidebar-products .append-filters').appendTo('.momments-filter-container');

      $('.momments-filter-container .wcpf-radio-list').addClass('scrolling-nav');
      $('.momments-filter-container .wcpf-radio-list .wcpf-item').addClass('scrolling-nav-items');
    }
    TweenLite.delayedCall(0.5, () => {
      if (document.querySelector('.category-filters .wcpf-item-label')) {
        Array.from(document.querySelectorAll('.wcpf-simple-box-heading')).forEach(function (element, index) {
          element.addEventListener('click', function () {
            document.querySelectorAll('.category-filters .wcpf-item-label')[index].dispatchEvent(new Event('click'));
          });
        });

        Array.from(document.querySelectorAll('.category-filters .wcpf-item-label')).forEach(function (element, index) {
          if (element.querySelector('input').checked===true){
            document.querySelectorAll('.wcpf-simple-box-heading')[index].classList.add("active");
          }
          element.addEventListener('click', function () {
            if (element.querySelector('input').checked === true) {
              document.querySelectorAll('.wcpf-simple-box-heading')[index].classList.add("active");
            } else {
              document.querySelectorAll('.wcpf-simple-box-heading')[index].classList.remove("active");
            }
          });
        });
      }
      if ($('.sidebar-products .wcpf-item-label').length > 0) {
        // $('.sidebar-products .wcpf-item-label').on('click.sidebarFilter', function (e) {
        //   if ($('body').hasClass('filters-opened')) $('body').removeClass('filters-opened overHidden-filters');
        // });
        $('.sidebar-products .wcpf-button-action-reset').on('click.sidebarReset', function () {
          if ($('body').hasClass('filters-opened')) $('body').removeClass('filters-opened overHidden-filters');
        });
      }
    });

    $('.produtos-list').isotope({
      itemSelector: '.product-divs',
      percentPosition: true,
      masonry: {
        // use element for option
        columnWidth: '.grid-sizer'
      }
    });

    if ($('.compare-basket .product-icon').length>0){
      $('.compare-basket .product-icon').remove();
    }

    if ($('.product-divs').length > 0) {
      $('.product-divs').each(function(){
        if ($(this).find('script').length > 0 && $(this).find('iframe').length == 0 && !$(this).hasClass('shopify-loaded')){
          const oldScript = $(this).find('script');

          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.innerText = oldScript.html();
          
          $(script).insertAfter(oldScript);
          oldScript.remove();
          $(this).addClass('shopify-loaded');
        }
      });
    }

    initCompare();
  }
}
function catalogIntro() {
  if ($('body').innerWidth() > 960 && $('.catalog-intro').length > 0) {
    var controller = new ScrollMagic.Controller();

    var catalogIntro = new TimelineMax({});

    catalogIntro.to('.catalog-intro .intro_text > div', 1, {
      opacity: 0,
      ease: Expo.easeInOut
    }, 0);

    catalogIntro.to('.catalog-intro .style-color-142440-bg', 1, {
      backgroundColor: "#1f1f1c",
      ease: Expo.easeInOut
    }, 0.4);

    catalogIntro.to('.catalog-intro .catalog-fade', 1, {
      css: {
        opacity: 1,
        visibility: "visible",
        pointerEvents: "auto"
      },
      ease: Expo.easeInOut
    }, 0.4);

    $(".catalog-intro .div_absolute").each(function (index, element) {
      catalogIntro.to(this, 1, {
        width: "100%",
        left: "50%",
        ease: Expo.easeInOut
      }, 0);
    });
    
    // var scene = new ScrollMagic.Scene({ triggerElement: ".catalog-intro", triggerHook:0, offset:1})
    // .setTween(catalogIntro)
    // //.setPin(".catalog-intro")
    // .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: ".catalog-intro",
      triggerHook: "onLeave",
      duration: 1000,
      reverse: true
    })
    .setPin('.catalog-intro')
    .setTween(catalogIntro)
    .addTo(controller);
  }

  // let wheeled = false;
  // window.removeEventListener('wheel', catalogWhell);

  // if ($('body').innerWidth() > 960 && $('.catalog-intro').length > 0) {
  //   $(document).scrollTop(0);
  //   $('body').addClass('overHidden-catalogIntro');
    
  //   window.addEventListener('wheel', catalogWhell, true);
  // }

  // function catalogWhell(e) {
  //   if ($('.catalog-intro').length > 0) {
  //     const delta = e.deltaY;
  //     if (wheeled === false && delta >= 0) {
  //       $('.catalog-intro .intro_text > div').fadeOut('fast');

  //       wheeled = true;
  //       $(".catalog-intro .div_absolute").each(function (index, element) {
  //         TweenLite.to(this, 1, {
  //           width: "100%",
  //           left: "50%",
  //           ease: Expo.easeInOut
  //         }, 0);
  //       });
  //       TweenLite.delayedCall(0.5, () => {
  //         $('body').removeClass('overHidden-catalogIntro');
  //         $('.catalog-intro .intro_text').removeClass('fixed');

  //         $(".catalog-intro .style-color-142440-bg").addClass('animated');
  //         $(".catalog-intro .catalog-fade").addClass('animated');
  //       });
  //     } else if (wheeled === true && delta < 0 && $(document).scrollTop() === 0) {
  //       $('.catalog-intro .intro_text > div').fadeOut('fast');
  //       $('body').addClass('overHidden-catalogIntro');

  //       $(".catalog-intro .style-color-142440-bg").removeClass('animated');
  //       $(".catalog-intro .catalog-fade").removeClass('animated');

  //       wheeled = false;
  //       $(".catalog-intro .div_absolute").each(function (index, element) {
  //         TweenLite.to(element, 1, {
  //           width: "120%",
  //           left: index == 0 ? "-190%" : "-345%",
  //           ease: Expo.easeInOut,
  //           onComplete: function () {
  //             $('.catalog-intro .intro_text').addClass('fixed');
  //             $('.catalog-intro .intro_text > div').fadeIn('slow');
  //           }
  //         }, 0);
  //       });
  //     }
  //   }
  // }
}
function sideScroll() {
  let controller = null;
  let scene = null;
  let horizontalSlide = null;
  let sectionsHeight = 0;
  const width = $('.scroller-section').width();

  if ($('html').hasClass('no-touch') && $('.scroller-section').length > 0) {
    $('body').addClass('scroller-effect');
    controller = new ScrollMagic.Controller({});

    $('<div class="fake-container"></div>').insertBefore('.scroller-section');

    $('.scroller-section>div>div:visible').each((i, $el) => {
      $($el).css('z-index', (i+1));
      $('<div class="fake-div fake-' + i + '"></div>').appendTo('.fake-container');
            
      sectionsHeight += 4;

      if (i > 0) {
        let duration = "400%";

        horizontalSlide = new TimelineMax({});
        if ($($el).hasClass('section-fade')){
          $('.fake-' + i).css('height', "600vh");
          sectionsHeight += 2;
                    
          horizontalSlide.fromTo($el, .3, 
            { autoAlpha: 0 }, 
            { autoAlpha: 1, ease: Power0.easeNone }, 
            0);
        }else{
          horizontalSlide.fromTo($el, .3, 
            { x: width },
            { x: 0, ease: Power0.easeNone}, 
            0);
        }
                
        if ($($el).find('.inner-animation.inner-delay').length>0){
          $('.fake-' + i).css('height', (($($el).find('.inner-animation.inner-delay').length * 4) + 4) + "00vh");
        }               

        scene = new ScrollMagic.Scene({ triggerElement: '.fake-' + i, triggerHook: 1, duration: duration });
        scene.setTween(horizontalSlide).addTo(controller);
      }

      if ($($el).find('.inner-animation').length>0){
        $($el).find('.inner-animation').each(function (index, element){
          let from = "25%";
          let to = "-75%";

          if ($(element).attr('data-fromto')){
            const values = $(element).attr('data-fromto').split(",");
            from = values[0];
            to = values[1];
          }
          if($(element).hasClass('moving')){
            const heightPercent = $('.fake-' + i).height() * 0.15;

            new ScrollMagic.Scene({ triggerElement: '.fake-' + i, triggerHook: 0, offset: heightPercent, duration: '600%' })
              .setTween(new TimelineMax().fromTo(element, 0.7, {
                x: from,
                force3D: true,
              }, {
                x: to,
                force3D: true,
                ease: Power2.easeInOut,
              }, 0))
              .addTo(controller);
          }else if($(element).hasClass('fading')){
            const heightPercent = $('.fake-' + i).height() * 0.65;

            new ScrollMagic.Scene({ triggerElement: '.fake-' + i, triggerHook: 0, offset: heightPercent, duration: '200%' })
              .setTween(new TimelineMax().fromTo(element, 0.5, {
                opacity: 0
              }, {
                opacity: 1,
                ease: Power2.easeInOut
              }, 0))
              .addTo(controller);
          }
        });
      }
            
    });

    // scene = new ScrollMagic.Scene({
    //     triggerElement: ".fake-container",
    //     triggerHook: 1,
    //     duration: (sectionsHeight+2) + "00%",
    // })
    // .setClassToggle(".scroller-section", "active")
    // .addTo(controller);

    controller.scrollTo(function (el) {
      $('html, body').stop(true, true).animate({
        scrollTop: el
      }, 1500);
    });

    $('.scrollLink').off('click');
    $('.scrollLink').on('click', function (e, index) {
      let $this = $(this);
      if ($this.find('a').length > 0) $this = $this.find('a');
      const id = $('[data-name="' + $this.attr('href').replace("#", '') + '"]');
            
      if (id !== null && $(id).length > 0) {
        e.preventDefault();
        const el = '.fake-' + $(id).index();
        const scrolling = $(el).offset().top + ($(window).height()*3);
        if (controller && scene) controller.scrollTo(scrolling);
      }
    });
        
    $(window).off('scroll.scrollingContainer');
    $(window).on('scroll.scrollingContainer', function () {
      if (controller && scene && $('.fake-container').length>0) {
        var top = $(document).scrollTop();
        var height = $(window).height();
        var section = $('.fake-container').offset().top;
        var end = section + $('.fake-container').height() + height;

        if (top + height >= section && top + height < end) $(".scroller-section").addClass('active');
        else $(".scroller-section").removeClass('active');
      }else{
        $(window).off('scroll.scrollingContainer');
      }
    });

    $(window).resize(function(){
      if (controller && scene && $('body').innerWidth()<=960){
        scene.removeTween();
        controller.destroy(true);
        scene.destroy(true);
        controller = null;
        scene = null;
        horizontalSlide = null;

        $('.fake-container').remove();
        $('.scroller-section:visible > div').removeAttr('style');
        $('.scroller-section:visible>div>div').removeAttr('style');

        sideScroll();
      }
    });
  }

  window.dispatchEvent(new Event('scroll'));
}


function detailCompare() {
  $('.compare-product-link > input').trigger('click');
}
