/*  **************** PAGE LOADER ****************  */
const $domainParts = window.location.href.split('/');
const $domain = `${$domainParts[0]}//${$domainParts[2]}`;
const internalLinks = `a[href^='${$domain}'], a[href^='/'], a[href^='./'], a[href^='../']`;
const newMetas = ['[rel="shortlink"]', '[rel="canonical"]', '[property="og:title"]', '[property="og:url"]', '[property="og:image"]', '[name="twitter:title"]', '[name="twitter:image"]', '[name="description"]'];

const $body = $('body');
const $window = $(window);
const $html = $('html');

let globalAllowClick = 0; // When loading do not allow clicks by user
const $debug = 0;
let enteredHome = 0;
const $cursorActive = 0;
const $smoothScroll = 0;
const $pageloaderActive = 0;
let $pageloaderStarted = 0;
const $gaActive = 0;

const introActive = localStorage.getItem("introActive") ? localStorage.getItem("introActive") : true;
const menuElement = '.overlay-menu';

let $urlParams = '';
let $forPopstate = 1;
let $currentPage = '';
let urlOld = '';

let $loaderState = 'finish';
let $loaderTimeout = 0;
const $loaderTimer = 1;

function loadingTransition(state, f) {
  const $loading = '.loaderTransition';

  if (state === 'start') {
    new TimelineMax({
      onComplete() {
        $body.addClass('loader-active');
        if (typeof f === 'function') f();
        if ($debug) console.log('loader started completed');
      },
    }).to($loading, $loaderTimer, {
      opacity: 1,
      y: 0,
      ease: Circ.easeInOut,
    });
  }

  if (state === 'finish') {
    new TimelineMax({
      onComplete() {
        if ($debug) console.log('loader completed');
        $body.removeClass('loader-active');

        TweenLite.delayedCall(1, () => {
          $('.page-current').removeClass('not-visible');
          TweenLite.set([$loading, '.page-current', '.page-current .page-body'], {
            clearProps: 'all',
          });
        });

        if (typeof f === 'function') f();
        $body.removeClass('loading-page');
        globalAllowClick = 1;
      },
    }).from($loading, 0, {
      y: '0%',
    }).to($loading, 2, {
      y: '-100%',
      ease: Circ.easeInOut,
    }, '-=.8').to('.page-current', 0.5, {
      y: '0%',
      ease: Power1.easeOut,
    })
    .to('.page-current .page-body', 0.5, {
      opacity: '1',
      ease: Power1.easeOut,
    }, '-=.5')
    .to('.page-current .page-body', 1.25, {
      y: '0',
      ease: Power2.easeOut,
    }, '-=2');
  }

  $loaderState = state;
}

function onStartPageWhenRefresh(byRefresh) {
  if ($debug) console.log('onStartPageWhenRefresh');

  if ($('.home_intro').length > 0 && introActive != "false") {
    $('#masthead').addClass('hidden');
    $('footer.site-footer').addClass('hidden');
    $('body').addClass('overHidden-intro');

    init_fades();

    TweenLite.delayedCall(0.2, () => {
      $('#iubenda-cs-banner').addClass('hidden');
    });

    if ($('.mobile-holdcontinue').length > 0) {
      $('.mobile-holdcontinue').on('click', function (e) {
        e.preventDefault();

        if (enteredHome == 0) {
          $(this).attr('disabled', true);
          homeIntro();
        }
      });
    }
  } else {
    $('.home_intro').remove();
  }

  if (byRefresh === 1 && $pageloaderActive === 1) {
    $window.scrollTop(0);
    $('html,body').scrollTop(0);

    customScripts();
    initScripts();
  } else {
    const delay = $('.loader svg').length > 0 ? 0.3 : 0.1;

    if ($('.home_intro').length === 0) {
      customScripts();
      initScripts();
    }

    TweenLite.delayedCall(delay, () => {
      $('.loader').fadeOut('fast', () => {
        $body.removeClass('loading-page');
        globalAllowClick = 1;
      });
    });
  }
}
function clearPagesAfterloading(delay) {
  TweenLite.delayedCall(delay, () => {
    if ($debug) console.log('clearPagesAfterloading');
    onStartPageWhenRefresh(1);
  });
}

function handleMetas(response, isLoad) {
  if ($debug) console.log('altera metatags');

  if (response.find('title').length>0) document.title = response.find('title')[0].innerText;

  for (let i = 0, len = newMetas.length; i < len; i++) {
    if ($(newMetas[i]).attr('content') !== '') {
      $(newMetas[i]).attr('content', $(newMetas[i], response).attr('content'));
    } else if ($(newMetas[i]).attr('href') !== '') {
      $(newMetas[i]).attr('href', $(newMetas[i], response).attr('href'));
    }
  }

  handleParams();

  //urlOld = window.location.href;

  if ($forPopstate) {
    history.pushState({}, document.title, $currentPage);
    window.history.replaceState('Object', document.title, $currentPage);
  }

  if ($gaActive && isLoad === 0) {
    if (typeof ga !== 'undefined') {
      const d = location.pathname;
      ga('set', { page: d, title: document.title });
      ga('send', 'pageview');
    }
  }

  $forPopstate = 1;
}

function handleParams() {
  if ($urlParams) {
    if ($loaderState === 'finish' && $('.loader').css('display') === 'none') {
      clearInterval($loaderTimeout);
      if ($debug) console.log(`handleParams: ${$urlParams}`);

      if ($urlParams.indexOf('&') > 0) {
        const allParams = $urlParams.split('&');
        allParams.forEach((param) => {
          const paramSplited = param.split('=');
          if (paramSplited[0] === 'anchor') {
            $('html').stop(true, true).animate({
              scrollTop: $(`#${paramSplited[1]}`).offset().top,
            }, 1500, 'easeInOutExpo', () => { });
          }
        });
      } else {
        const paramSplited = $urlParams.split('=');
        if (paramSplited[0] === 'anchor') {
          $('html').stop(true, true).animate({
            scrollTop: $(`#${paramSplited[1]}`).offset().top,
          }, 1500, 'easeInOutExpo', () => { });
        }
      }
    } else {
      clearInterval($loaderTimeout);
      $loaderTimeout = setInterval(() => {
        handleParams();
      }, 100);
    }
  }
}

function initRemote() {
  if ($debug) console.log('initRemote');

  $body.on('click', 'a[data-remote="true"]', function (event) {
    event.preventDefault();
    const $this = $(this);
    if (!$this.hasClass('wpml-ls-link')) {
      if ($debug) console.log(`remote link: ${$this.attr('href')}`);

      $body.addClass('loading-page');

      // HANDLE URL
      const ajaxUrl = $this.attr('href');
      let ajaxUrlParams = $this.attr('href');

      if ($this.attr('href').indexOf('?') > 0) {
        if ($debug) console.log(`found params on remote btn: ${$urlParams}`);

        const ajaxUrlField = $this.attr('href').split('?');
        ajaxUrlParams = ajaxUrlField[0];
        $urlParams = ajaxUrlField[1];
      } else {
        $urlParams = '';
      }

      // if (ajaxUrl != $currentPage) {
      if ($debug) console.log('btn remote clicked');

      $('nav#menu .current').removeClass('current');
      if ($this.parent('.menu-page').length > 0) {
        $this.parent('.menu-page').addClass('current');
      }

      //$currentPage = ajaxUrlParams;
      $currentPage = ajaxUrl;
      
      if (parseInt($this.attr('data-product')) === 1) {
        if ($debug) console.log('btn prod clicked');

        if ($this.find('.product-loader').length > 0) {
          const $prodLoader = $this.find('.product-loader');
          $prodLoader.addClass('active');
          TweenLite.to($prodLoader, 0.5, {
            autoAlpha: 1,
          });
        }

        const $picture = $this.parents('figure').find('picture span.has_bg');
        const imgPath = $picture.attr('data-big');
        const $bigImage = $(`<div class="product-dummy-image"><span role="img" aria-label="" class="has_bg" style="background-image:url(${imgPath})"></span>`);

        if ($debug) console.log('dummy image loaded');

        const offsetTop = ($picture.offset().top - $window.scrollTop()); // 10 do efeito de hover
        const offsetLeft = $picture.offset().left;
        const prodWidth = $picture.width();
        const prodHeight = $picture.height();

        $bigImage.css({
          top: `${offsetTop}px`,
          left: `${offsetLeft}px`,
          width: `${prodWidth}px`,
          height: `${prodHeight}px`,
        });

        $('body').append($bigImage);

        if ($debug) console.log('dummy image appended');
      }

      if (!globalAllowClick) {
        if ($debug) console.log('globalAllowClick is 0, exit');
        $body.removeClass('loading-page');
        return false; // exit and have normal click
      }

      let pageTransition = 'default';
      if ($this.attr('data-pageTrans')) {
        pageTransition = $this.attr('data-pageTrans');
      }
      loadPages(ajaxUrl, pageTransition);
      globalAllowClick = 0;

      // } else {
      //     if ($debug) {
      //         console.log($currentPage + "!=" + ajaxUrlParams);
      //     }
      // }
    }
  });
}
function lookForActive() {
  const path = document.location.href.substr(document.location.href.lastIndexOf('/') + 1);

  if (path) {
    if ($debug) console.log(`looking for active link: ${path}`);

    const $element = $(`a[data-remote="true"][href*="${path}"]`);

    if ($element.length > 0) {
      if ($debug) console.log('found active');

      $currentPage = $element.attr('href');

      if ($currentPage.indexOf('?') > 0) {
        const ajaxUrlField = $currentPage.split('?');
        $currentPage = ajaxUrlField[0];
      }

      if ($element.parent('.menu-page').length > 0) {
        $element.parent('.menu-page').addClass('current');
      }
    }
  }
}

function loadPages(newContent, pageTransition) {
  const $nextContent = $('.box-wrapper'); // can't be global

  if (pageTransition === 'default') { // animation by default
    if ($debug) console.log('page: default');

    $.ajax({
      type: 'GET', // or GET
      dataType: 'html', // or html, text
      url: newContent,
      success(response) {
        loadingTransition('start', () => {
          if (!response) {
            window.location = newContent;
            return;
          }

          if ($(menuElement).length > 0 && $body.hasClass('menu-opened')) {
            $('#masthead .mobile-menu-button').trigger('click');
          }
          if ($('body > .compare-basket').length > 0) {
            $('body > .compare-basket').remove();
            $('body > .compare').remove();
          }
                   
          const newDoc = (new DOMParser()).parseFromString(response, 'text/html');
          const newDocBody = $(newDoc.body);
        
          const metatags = $(newDoc.head);
          const bodyClass = `${newDocBody.attr('class')} loading-page`;
          const content = $('.box-wrapper', newDocBody).contents();
          
          TweenLite.delayedCall(0.4, () => {   
            $body.attr('class', bodyClass);
            $nextContent.html(content);

            $(internalLinks, $nextContent).attr('data-remote', 'true');
            $('.wpml-ls-link', $nextContent).removeAttr('data-remote');

            if ($('.product-dummy-image').length > 0) {
              const $parentImg = $('.main-image', $nextContent);
              const $detailImg = $('.main-image .uncol picture .has_bg', $nextContent);

              //const offsetTop = $body.height() - $('.main-image .uncol .uncode-single-media').height() - $('.main-image .uncol .button').outerHeight(true) + 2;
              const offsetTop = ($('#masthead > div').outerHeight(true));
              //const offsetLeft = ($body.innerWidth() / 2 - $detailImg.width() / 2) - 2;
              const offsetLeft = $('.main-image .uncol picture .has_bg').offset().left;
              const prodWidth = $detailImg.width();
              const prodHeight = $detailImg.height();

              // console.log(offsetTop, offsetLeft);
              // console.log($('.main-image .uncol picture .has_bg').position().top, $('.main-image .uncol picture .has_bg').position().top);

              TweenLite.set($parentImg, {
                opacity: 0,
              });

              TweenLite.to('.product-dummy-image', 1, {
                width: `${prodWidth}px`,
                height: `${prodHeight}px`,
                top: `${offsetTop}px`,
                left: `${offsetLeft}px`,
                className: '+=contain',
                ease: Expo.easeInOut,
              });
            }
          });
          TweenLite.delayedCall(0.8, () => {
            handleMetas(metatags, 0);
            clearPagesAfterloading(0);

            TweenLite.delayedCall(0.6, () => {
              loadingTransition('finish', () => {                                
                document.dispatchEvent(new Event('DOMContentLoaded'));
                window.dispatchEvent(new Event('load'));

                UNCODE.init();

                TweenLite.delayedCall(0.5, () => {
                  if ($('.produtos-list').length > 0) {
                    var id = $('.wcpf-filter').attr('id').match(/(\d+)/)[0];
                    var script = document.createElement('script');
                    script.id = `wcpf-load-project-${id}-script`;
                    script.type = 'text/javascript';
                    script.innerText = document.getElementById(`wcpf-load-project-${id}-script`).innerHTML;

                    document.getElementById(`wcpf-load-project-${id}-script`).remove();
                    $(script).insertAfter(`#wcpf-filter-${id}`);
                  }
                  
                  if ($('.iubenda-embed').length > 0){
                    var script = document.createElement('script');
                    script.id = 'iubenda-script';
                    script.type = 'text/javascript';
                    script.innerText = document.querySelector('.iubenda-embed + script').innerHTML;

                    document.querySelector('.iubenda-embed + script').remove();
                    $(script).insertAfter('.iubenda-embed');
                  }
                });

                if ($('.product-dummy-image').length > 0) {
                  TweenLite.set('.main-image', {
                    opacity: 1,
                  });
                  
                  $('.product-dummy-image').fadeOut('fast').remove();
                }        
              });
            });
          });
        });
      },
      error(jqXHR, textStatus, errorThrown) {
        window.location = newContent;
      },
      cache: false,
    });
  }
}

/* **************** FUNCTIONS **************** */
function initScrollIndicator() {
  if (!document.getElementById('progress-bar')) $('<div id="progress-bar"></div>').appendTo('body');
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  const scrolled = (winScroll / height) * 100;
  document.getElementById('progress-bar').style.width = `${scrolled}%`;
}

function initSmoothScroll() {
  if ($smoothScroll === 1) {
    $body.addClass('overHidden-scrolling');
    var Scrollbar = window.Scrollbar;
    Scrollbar.init(document.querySelector('.box-wrapper'));
  }
}
function goTo(obj, center) {
  if ($(obj).length > 0) {
    if (center == 1) {
      const elOffset = $(obj).offset().top;
      const elHeight = $(obj).height();
      const windowHeight = $window.height();
      var offset;

      const cenas = (windowHeight / 2);
      const cenas2 = (elHeight / 2);

      if (elHeight < windowHeight) {
        offset = elOffset - (cenas - cenas2);
      }

      $('html, body').stop(true, true).animate({
        scrollTop: offset,
      }, 1500, '', () => { });
    } else {
      var offset = $(obj).offset().top;
      $('html, body').stop(true, true).animate({
        scrollTop: offset,
      }, 1500, '', () => { });
    }
  }
}
function initIframes() {
  if ($('.video_frame').length > 0) {
    $('.video_frame').each(function (index, element) {
      let vid = $(this).attr('data-vid');

      if (vid && !$(element).hasClass('framed')) {
        if (vid.indexOf('/watch?v=') !== -1) {
          vid = vid.split('/watch?v=');
          vid = vid[vid.length - 1];
          if (vid.indexOf('&') !== -1) {
            vid = vid.split('&');
            vid = vid[0];
          }
        } else if (vid.indexOf('/') !== -1) {
          vid = vid.split('/');
          vid = vid[vid.length - 1];
          if (vid.indexOf('?') !== -1) {
            vid = vid.split('?');
            vid = vid[0];
          }
        }

        let embed;
        const div = document.createElement('div');
        div.setAttribute('data-vid', vid);
        div.classList.add('has_bg');

        if ($(element).hasClass('youtube')) {
          var thumb = 'hqdefault';
          embed = `https://www.youtube.com/embed/${vid}?autoplay=1&enablejsapi=1`;

          if (typeof $(element).attr('data-coverimg') !== 'undefined') {
            thumb = $(element).attr('data-coverimg');
          } else {
            if ($(element).hasClass('full')) {
              thumb = 'maxresdefault';
            }

            if ($(element).parents('[data-thumb]').length > 0) {
              $(element).parents('[data-thumb]').attr('data-thumb', `https://i.ytimg.com/vi/${vid}/default.jpg`);
            }

            thumb = `https://i.ytimg.com/vi/${vid}/${thumb}.jpg`;
          }

          div.style.backgroundImage = `url('${thumb}')`;
        } else if ($(element).hasClass('vimeo')) {
          embed = `https://player.vimeo.com/video/${vid}?autoplay=1`;
          var thumb;

          if ($(element).attr('data-coverimg') == '') {
            $.getJSON(`http://www.vimeo.com/api/v2/video/${vid}.json?callback=?`, { format: 'json' }, (data) => {
              thumb = data[0].thumbnail_large;

              if ($(element).hasClass('full')) {
                var thumbSplit = thumb.split(/\d{3}(?=.jpg)/);
                thumb = `${thumbSplit[0]}1920x1080${thumbSplit[1]}`;
              }

              if ($(element).parents('[data-thumb]').length > 0) {
                $(element).parents('[data-thumb]').attr('data-thumb', `${thumbSplit[0]}100x100${thumbSplit[1]}`);
              }
            });
          } else {
            thumb = $(element).attr('data-coverimg');
          }

          div.style.backgroundImage = `url('${thumb}')`;
        }


        $(div).html('<div class="play"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="314.068px" height="314.068px" viewBox="0 0 314.068 314.068" style="enable-background:new 0 0 314.068 314.068;" xml:space="preserve"><g><g id="_x33_56._Play"><g><path d="M293.002,78.53C249.646,3.435,153.618-22.296,78.529,21.068C3.434,64.418-22.298,160.442,21.066,235.534c43.35,75.095,139.375,100.83,214.465,57.47C310.627,249.639,336.371,153.62,293.002,78.53z M219.834,265.801c-60.067,34.692-136.894,14.106-171.576-45.973C13.568,159.761,34.161,82.935,94.23,48.26c60.071-34.69,136.894-14.106,171.578,45.971C300.493,154.307,279.906,231.117,219.834,265.801z M213.555,150.652l-82.214-47.949c-7.492-4.374-13.535-0.877-13.493,7.789l0.421,95.174c0.038,8.664,6.155,12.191,13.669,7.851l81.585-47.103C221.029,162.082,221.045,155.026,213.555,150.652z"/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></div>');

        $(div).click(() => {
          const iframe = document.createElement('iframe');

          iframe.setAttribute('src', embed);
          iframe.setAttribute('frameborder', '0');
          iframe.setAttribute('allowfullscreen', '1');
          this.parentNode.replaceChild(iframe, this);
        });

        element.appendChild(div);
        $(element).addClass('framed');
      }
    });
  }
}
function initVideo() {
  if ($('video').length > 0) {
    
    if ($('html').hasClass('ios')) {
      $('video').attr('controls', 'controls');
    }
    if ($('html').hasClass('touch')) {
      $('video').removeAttr('autoplay');
    }

    $('video').each((index, element) => {
      const watcher = scrollMonitor.create(element);

      watcher.enterViewport(() => {
        if (element.paused && $(element).hasClass('autoplay')) {
          element.play();
        }
      });
      watcher.exitViewport(() => {
        if (!element.paused) {
          element.pause();
        }
      });
    });

    jQuery('video, object').maximage('maxcover');
  }
}
function debounce(fn, threshold) {
  let timeout;
  threshold = threshold || 100;
  return function debounced() {
    clearTimeout(timeout);
    const args = arguments;
    const _this = this;
    function delayed() {
      fn.apply(_this, args);
    }
    timeout = setTimeout(delayed, threshold);
  };
}
function elementParallax() {
  // if ($('.animated-parallax').length>0){
  //     var scrollMagicController = new ScrollMagic.Controller();

  //     $('.animated-parallax').each(function (index, element) {
  //         var neChild = $(this).find('.parallax-el'),
  //             data_y = neChild.attr('data-y'),
  //             data_invert = neChild.attr('data-invert');

  //         if (data_invert == 1) {
  //             var scrollMagicTween = new TimelineMax().add([
  //                 TweenLite.fromTo(neChild, 1, { y: -data_y }, { y: data_y, ease: Linear.easeNone }),
  //             ]);
  //         } else {
  //             var scrollMagicTween = new TimelineMax().add([
  //                 TweenLite.fromTo(neChild, 1, { y: data_y }, { y: -data_y, ease: Linear.easeNone }),
  //             ]);
  //         }

  //         var scrollMagicScene = new ScrollMagic.Scene({
  //             triggerElement: element,
  //             triggerHook: 1,
  //             duration: '200%'
  //         }).setTween(scrollMagicTween).addTo(scrollMagicController);
  //     });
  // }
}

function getRandom(min, max) {
  return (Math.random() * (max - min) + min).toFixed(2);
}
function initSrcSet() {
  var bgss = new bgsrcset();
  bgss.callonce = false;
  if ($('[bg-srcset]').length > 0) {
    $('[bg-srcset]').each((index, element) => {
      bgss.init(element);
    });
  }
}
function init_fades() {
  // Elements Fading
  if ($('.animate_when_almost_visible').length > 0) {
    $('.animate_when_almost_visible').each((index, element) => {
      $(element).addClass('elements_animated').removeClass('animate_when_almost_visible');
      const watcher = scrollMonitor.create(element);

      watcher.enterViewport(() => {
        $(element).addClass('active');

        TweenLite.delayedCall(2, () => {
          $(element).removeClass('elements_animated active');
        });

        watcher.destroy();
      });
    });
  }
  if ($('.will-appear').length > 0) {
    $('.will-appear').each((index, element) => {
      const watcher = scrollMonitor.create(element);

      watcher.enterViewport(() => {
        $(element).addClass('appeared');
        watcher.destroy();
      });
    });
  }
}

function init_inputs() {
  if ($('.field_holder').length > 0) {
    $('.field_holder').each((index, element) => {
      $('input, textarea', element).on('keypress focus', () => {
        if ($(this).val() !== '') {
          $(this).parents('.field_holder').addClass('focused');
        } else {
          $(this).parents('.field_holder').removeClass('focused');
        }
      });
      $('input, textarea', element).on('blur', () => {
        if ($(this).val() !== '') {
          $(this).parents('.field_holder').addClass('focused filled');
        } else {
          $(this).parents('.field_holder').removeClass('focused filled');
        }
      });
    });
  }
}


/* -------------------------------------------------------------------------------------------
=HANDLE MENU EFFECT - RUI
-------------------------------------------------------------------------------------------- */
function triggerMobileMenu() {
  if ($(menuElement).length > 0) {
    $body.toggleClass('overHidden-menu menu-opened');
    $(menuElement).toggleClass('open open-items');
    $(menuElement).find('.main-menu-container').toggleClass('open-items');
    $('#masthead .mobile-menu-button').toggleClass('close');
  }
}

function initMenuMobile() {
  if ($(menuElement).length > 0) {
    $('#masthead .mobile-menu-button').off('click.menuMobile');
    $('#masthead .mobile-menu-button').on('click.menuMobile', () => {
      triggerMobileMenu();
    });
  }
}

/* **************** PLUGINS **************** */
(function ($) { // $window.scrolled(function() {	 Utilizar em vez de window on scroll, corre o codigo apenas quando o utilizar para o scroll
  let uniqueCntr = 0;
  $.fn.scrolled = function (waitTime, fn) {
    if (typeof waitTime === 'function') {
      fn = waitTime;
      waitTime = 200;
    }
    const tag = `scrollTimer${uniqueCntr++}`;
    this.scroll(() => {
      const self = $(this);
      let timer = self.data(tag);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        self.removeData(tag);
        fn.call(self[0]);
      }, waitTime);
      self.data(tag, timer);
    });
  };
}(jQuery));

(function ($) { // $window.resized(function() {	 Utilizar em vez de window on resize, corre o codigo apenas quando o utilizar para o resize
  let uniqueCntr = 0;
  $.fn.resized = function (waitTime, fn) {
    if (typeof waitTime === 'function') {
      fn = waitTime;
      waitTime = 200;
    }
    const tag = `resizeTimer${uniqueCntr++}`;
    this.resize(() => {
      const self = $(this);
      let timer = self.data(tag);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        self.removeData(tag);
        fn.call(self[0]);
      }, waitTime);
      self.data(tag, timer);
    });
  };
}(jQuery));


/* **************** INITS **************** */
// document.addEventListener('DOMContentLoaded', () => {
$window.on('load', () => {
  if ($pageloaderStarted == 0) {
    if ($debug) console.log('doc ready');

    const templateUrl = phpVars.stylesheet_directory_uri;

    $pageloaderStarted = 1;
    onStartPageWhenRefresh(0);

    if ($pageloaderActive == 1) {
      $(internalLinks, '.box-container').attr('data-remote', 'true');
      $('.wpml-ls-link').removeAttr('data-remote');
      $('[rel^="external"]').removeAttr('data-remote');

      let params = document.location.href.substr(document.location.href.lastIndexOf('/') + 1);
      if (params.indexOf('?') > 0) {
        params = params.split('?');
        $urlParams = params[1];
      }

      //* * =INIT CLCKS
      initRemote();

      //* * =LOOK FOR ACTIVE
      lookForActive();

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`${phpVars.stylesheet_directory_uri}/service-worker.js`);
      }

      // HISTORY
      if (window.addEventListener) {
        window.addEventListener('popstate', (e) => { // firefox vs webkit and safari trigers on
          if ($debug) console.log('entrou no popstate');
          if (!$html.hasClass('mobile')) {
            if ($debug) console.log(e);
            if ($debug) console.log(`${e.state}||${urlOld}!=${window.location.href}`);
            if (e.state || urlOld != window.location.href) {
              $forPopstate = 0;
              TweenLite.delayedCall(0.5, () => {
                window.location = window.location;
              });
            } else {
              $forPopstate = 1;
            }
          }
        });// endif: does not excute for <= IE8
      }
    }

    WebFont.load({
      // google: {
      //   families: ['Merriweather:700', 'Open+Sans:400,500,700'],
      // },
      custom: {
        families: ['Lora:n4,i4,n7,i7', 'Aviano Sans:n4'],
        urls: [templateUrl + '/dist/fonts.css']
      }
    });
    /* -------------------------------------------------------------------------------------------
    =KEYS
    -------------------------------------------------------------------------------------------- */
    $(document).on('keydown', (event) => {
      switch (event.which) {
        case 40: // down
          // return false;
          break;
        case 38: // up
          // return false;
          break;
        case 13: // enter
          // return false;
          break;
        case 39: // next
          // return false;
          break;
        case 37: // prev
          // return false;
          break;
        case 27:

          if ($(menuElement).length > 0 && $body.hasClass('menu-opened')) {
            triggerMobileMenu();
          }
          break;
        default:
          break;
      }
    });
  }
});


function homeIntro() {
  if (enteredHome === 0) {
    enteredHome = 1;
    // $('.home_intro').addClass('animated');

    loadingTransition('start', () => {
      TweenLite.delayedCall(1, () => {
        $('#masthead').removeClass('hidden');
        $('footer.site-footer').removeClass('hidden');
        $('#iubenda-cs-banner').removeClass('hidden');
        $('body').removeClass('overHidden-intro');

        $('.home_intro').remove();
        localStorage.setItem('introActive', false);

        // $.ajax({
        //   type: 'post',
        //   dataType: 'json',
        //   url: phpVars.ajaxurl,
        //   data: `action=mostraEntrada&security=${phpVars.entrada_nonce}`,
        // });

        

        loadingTransition('finish', () => {
          customScripts();
          initScripts();
        });
      });
      
    });
  }
}

function initScripts() {
  $body.addClass('doc-ready');
  initMenuMobile();
  initSrcSet();

  initIframes();
  initVideo();
  init_fades();
  init_inputs();
  elementParallax();

  initSmoothScroll();

  initMiewSlider();
  initModals();

  initMapbox();
  
  $window.scroll(() => {
    initScrollIndicator();
  });
}
