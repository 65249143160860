function initMapbox(){
    if ($('#mapbox').length > 0){
        if (!mapboxgl.supported()) {
            console.log('Your browser does not support Mapbox GL');
        }else{            
            const center = JSON.parse($('.map_container').attr('data-center'));
            const style = $('.map_container').attr('data-style');
            const items = $('.map_container').attr('data-items');

            let geojson = JSON.parse(items);

            mapboxgl.accessToken = 'pk.eyJ1IjoibWlld2RldiIsImEiOiJjanB0dGdpNnkwMG5oM3hxcnBpbXQ5ZnFpIn0.vdBj-XtkNhDfPD2K_8yh1Q';
            let map = new mapboxgl.Map({
                container: 'mapbox', // container id
                style: style, //hosted style id
                center: [parseFloat(center[0]), parseFloat(center[1])],
                zoom: 12,
                pan: 0,
            });

            // add markers to map
            let $i = 1;
            geojson.forEach(function (marker) {
                // add marker to map
                const el = document.createElement('div');
                el.className = 'marker';
                el.innerHTML = "<span>0" + $i + ".</span> <p>" + marker.title[0] + "</p>";

                const coordenadas = marker.coordenadas[0].split(',');

                var markerPopup = "";
                if (marker.title[0] || marker.text[0] || (marker.link[0] && marker.linktext[0])){
                    let markerHtml = '<div class="marker-popup">';
                    
                    if (marker.title[0]) markerHtml += '<h4 class="third-color">' + marker.title[0] + '</h4>';
                    if (marker.text[0]) markerHtml += '<p class="primary-color">' + marker.text[0] + '</p>';
                    if (marker.link[0] && marker.linktext[0]) markerHtml += '<a class="button" href="' + marker.link[0] + '" target="' + marker.linktarget + '">' + marker.linktext[0] + '<i class="fa fa-arrow-right2"></i></a></div>';
                    
                    markerHtml += '</div>';

                    markerPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(markerHtml);
                    
                }

                new mapboxgl.Marker(el).setPopup(markerPopup).setLngLat([parseFloat(coordenadas[0]), parseFloat(coordenadas[1])]).addTo(map);

                $i++;

                el.addEventListener('click', function () {
                    var offsetY = parseInt(($(window).height() / 2) * 0.75);

                    map.flyTo({
                        center: [parseFloat(coordenadas[0]), parseFloat(coordenadas[1])],
                        offset: [0, -offsetY],
                        zoom: 14
                    });

                    $('.marker.active').removeClass('active');
                    $(el).addClass('active clicked');

                });
            });

            map.on('resize', function () {

            });

            if($('body').innerWidth()<960){
                map.dragRotate.disable();
                map.dragPan.disable();
            }
            
            map.touchZoomRotate.disable();
            map.scrollZoom.disable();

            map.addControl(new mapboxgl.NavigationControl());
        }
    }
}