let modalTimeout;
let modalTimeout2;

function initModals(){
    let modals = [];

    if ($('.miew-modal').length>0){       
        $('.miew-modal').each(function(){
            const $modal = $(this);
            const modalID = $modal.attr('id');            
            if ($('.main-wrapper #' + modalID).length>0){
                $('#' + modalID).insertAfter('.main-wrapper');
            }

            if (modals.indexOf(modalID)<0){
                modals.push(modalID);

                if (!localStorage.getItem("modalOpened-" + modalID)){

                    const campanha = $modal.attr('data-campanha');
                    if (campanha) {
                        const campanhas = campanha.split(',');
                        for (let i = 0; i < campanhas.length; i++) {
                            if (window.location.href.indexOf(campanhas[i].trim()) > -1) {
                                modalTimeout2 = setTimeout(function () {
                                    clearTimeout(modalTimeout);
                                    $(window).off('scroll.modalScroll');

                                    openModal($modal, modalID);
                                }, 20000);
                                break;
                            }
                        }
                    }
                    
                    const tempo = parseInt($modal.attr('data-tempo'));
                    if (tempo > 0) {
                        modalTimeout = setTimeout(function () {
                            clearTimeout(modalTimeout2);
                            $(window).off('scroll.modalScroll');
                            
                            openModal($modal, modalID);
                        }, (tempo*1000));
                    }

                    const scroll = parseInt($modal.attr('data-scroll'));
                    if (scroll > 0) {
                        $(window).on('scroll.modalScroll', function () {
                            const scrollToAppear = (document.documentElement.scrollHeight*scroll)/100;
                            if ($(document).scrollTop() >= scrollToAppear) {
                                clearTimeout(modalTimeout);
                                clearTimeout(modalTimeout2);
                                $(window).off('scroll.modalScroll');

                                openModal($modal, modalID);
                            }
                        });
                    }
                }

                if ($('[href="#' + modalID + '"]').length > 0) {
                    $('[href="#' + modalID + '"]').off('click');
                    $('[href="#' + modalID + '"]').on('click', function (e) {
                        e.preventDefault();

                        if ($modal.hasClass('modal-active')) {
                            closeModal($modal);
                        } else {
                            openModal($modal, modalID);
                            clearTimeout(modalTimeout);
                            clearTimeout(modalTimeout2);
                            $(window).off('scroll.modalScroll');
                        }
                    });
                }
                if ($($modal).find('.miew-modal-close').length > 0) {
                    $($modal).find('.miew-modal-close').off('click');
                    $($modal).find('.miew-modal-close').on('click', function (e) {
                        e.preventDefault();
                        closeModal($modal);
                    });
                }

                $('body').on('click', function (e) {
                    'use strict';
                    if ($(e.target).closest('[href="#' + modalID + '"]').length == 0 && $(e.target).closest('.miew-modal-content').length == 0) {
                        if ($modal.hasClass('modal-active')) {
                            closeModal($modal);
                        }
                    }
                }); 

                $(document).on('keydown', (event) => {
                    if (event.which == 27){
                        if ($modal.hasClass('modal-active')) {
                            closeModal($modal);
                        }
                    }
                });
            }
        });       
    }

    function openModal($modal, modalID){
        if ($('body').hasClass('menu-opened')) {
            $('#masthead .mobile-menu-button').trigger('click');
        }

        $html.addClass('overHidden-modals');
        TweenLite.to($modal, 0.5, {
            autoAlpha: 1,
            ease: Power1.easeOut,
            onComplete: function () {
                $modal.addClass('modal-active');
                if ($modal.attr('data-store') != "false" && !localStorage.getItem("modalOpened-" + modalID)) { 
                    localStorage.setItem("modalOpened-" + modalID, true);
                }
            }
        });
    }
    function closeModal($modal) {
        $html.removeClass('overHidden-modals');
        TweenLite.to($modal, 0.5, {
            autoAlpha: 0,
            ease: Power1.easeOut,
            onComplete: function () {
                $modal.removeClass('modal-active');
            }
        });
    }
}