let previousTop = 0;
function initParallaxElements(){
    if ($('body').innerWidth() > 960) {
        initstickyParallax('[data-parallax]');
    } else {
        initstickyParallax('[data-parallax-mobile="true"]');
    }
}

function initstickyParallax($elements) {
    stickyParallaxHolder($elements);

    window.addEventListener('scroll', function(){
        stickParallax($elements);
    }, {
        capture: true,
        passive: true
    });


    $(window).resize(function () {
        stickyParallaxHolder($elements);
    });
}
function stickyParallaxHolder($elements) {
    $($elements).each(function (index, el) {
        const $element = $(el);
        const data = $element.data("parallax");
        const axis = data['axis'];


        if (axis == "x") {
            const fixed = data['fixed'];
            if (fixed) {
                $element.parent().height($element.height());
            }
        } else if (axis == "y" && data['parent']!=false) {
            let newHeight = $element.outerHeight(true);
            let stickyCont = $('.parallaxParent');

            if ($element.parent('.parallaxParent').length > 0) {
                stickyCont.css('height', newHeight + "px");
            } else {
                $element.wrap("<div class='parallaxParent'></div>");
                stickyCont = $element.parent('.parallaxParent');
                stickyCont.css('height', (newHeight + "px"));
            }
        }
    });
}
function stickParallax($elements) {
    //HANDLE SCROLL POSITION
    let currentTop = $(document).scrollTop();
    if (currentTop != previousTop) {
        setStickPosition($elements);
    }

    previousTop = currentTop;
}
function setStickPosition($elements){
    let currentTop = $(document).scrollTop();
    $($elements).each(function (index, el) {
        const $element = $(el);
        const data = $element.data("parallax");
        const axis = data['axis'];

        if (axis == "path") {
            const scrollTill = ($(this).parents('.row-inner').offset().top + $(this).parents('.row-inner').height());
            const totalDash = parseInt($element.css('stroke-dasharray'));

            const percentScroll = (currentTop * 100) / scrollTill;
            const percentDash = (percentScroll * totalDash) / 100;
            let delta = percentDash;

            if(delta<0) delta = 0;

            TweenLite.set($element, {
                strokeDashoffset: delta
            });
        } else if (axis == "x") {
            const fixed = data['fixed'];
            const centered = data['centered'];
            const direction = data['direction'];
    
            let scrollTill = ($(this).parents('.row-inner').offset().top + $(this).parents('.row-inner').height());
            let totalWidth = $element.outerWidth();

            if (fixed == "true") {
                scrollTill = ($('footer.site-footer').offset().top - $element.outerHeight()) - (($(window).height() / 2) - $element.outerHeight());
                totalWidth = $('body').innerWidth() + $element.outerWidth();
            }

            let percentScroll = (currentTop * 100) / scrollTill;
            let percentWidth = (percentScroll * totalWidth) / 100;
            let delta = percentWidth;

            if (fixed == "true") {
                delta = (percentWidth - $element.outerWidth());
            }
            
            const y = centered == true ? "-50%" : "0";
            TweenLite.to($element, 0, {
                x: direction == "right" ? -delta : delta,
                y: y,
                force3D: true,
                ease: Power0.easeNone
            });
        } else {
            //currentTop = currentTop + $('#masthead > div').height(); 

            const offset = data['offset'] ? parseInt(data['offset']) : 0;
            let contOffset = $element.parent().offset().top;
            let fixedTil = data['fixedTil'];

            if (fixedTil.includes(':')){
                const parent = fixedTil.split(':');
                fixedTil = $element.parents(parent[1]);    
            }else{
                fixedTil = $(fixedTil);
            }

            if (currentTop >= (contOffset - offset)) {
                if (fixedTil.length > 0) {
                    let elementHeight = $element.outerHeight(),
                        scrollTill = (fixedTil.offset().top + fixedTil.outerHeight()) - elementHeight;

                    if (currentTop >= (contOffset - offset) && currentTop < (scrollTill - offset)) {
                        let delta = currentTop - contOffset + offset;
                        
                        TweenLite.to(el, 0, {
                            y: delta,
                            force3D: true,
                            ease: Power0.easeNone
                        });
                    }
                }
            } else {
                $(el).removeAttr('style');
            }
        }
    });
}