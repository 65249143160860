function initMiewPosts() {
    if ($('.slick-postsgrid').length > 0) {
        const vertical = $('.slick-postsgrid').hasClass('vertical') ? true : false;

        if (!vertical) {
            $('.slick-postsgrid').slick({
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
                infinite: false,
                edgeFriction: 1,
                adaptiveHeight: false,
                autoplaySpeed: 8000,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        } else {
            $('.slick-postsgrid').on('init', function (event, slick, currentSlide, nextSlide) {
                $('.postsgrid-selector:eq(0)').addClass('active');
            });
            $('.slick-postsgrid').slick({
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                infinite: false,
                adaptiveHeight: false,
                fade: true,
                speed: 500,
                cssEase: 'linear',
                autoplaySpeed: 6000,
            });
            $('.slick-postsgrid').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                $('.postsgrid-selector.active').removeClass('active');
                $('.postsgrid-selector:eq(' + nextSlide + ')').addClass('active');
            });
            $('.postsgrid-selector').on('click', function () {
                const $index = $(this).index();
                $('.postsgrid-selector.active').removeClass('active');
                $(this).addClass('active');
                $('.slick-postsgrid').slick('slickGoTo', $index);
            });
        }
    }
}
