function initMiewSlider() {
    if ($('.slick-banners').length > 0) {
        $('.slick-banners').each(function(){
            var $this = $(this);

            if (($this.parent().hasClass('fullscreen') || $this.parent().hasClass('fullscreen-mobile')) && (!$('#masthead').hasClass('menu-absolute') && $('.menu-wrapper').css('position') !== "absolute")) {
                $(window).resize(function () {
                    var $responsiveW = 960;
                    var newH = 'auto';

                    if ($this.parent().hasClass('fullscreen') && $('body').innerWidth() > $responsiveW) {
                        newH = $('#masthead > div').outerHeight() + 'px';
                    } else if ($this.parent().hasClass('fullscreen-mobile') && $('body').innerWidth() <= $responsiveW) {
                        newH = $('#masthead > div').outerHeight() + 'px';
                    }

                    if (newH !== $this.parent().attr('data-margin')) {
                        $this.parent().attr('data-margin', newH);

                        if (newH != 'auto') {
                            $this.parent().css('height', '-webkit-calc(100vh - ' + newH + ')');
                            $this.parent().css('height', 'calc(100vh - ' + newH + ')');
                        } else {
                            $this.parent().css('height', newH);
                        }
                    }
                });
            }

            let prevArrowHTML = '<button class="slick-prev normal" aria-label="Previous" type="button"><svg viewBox="0 0 7 12"><path d="M5.82412 0.5L0.893366 6.09028L5.92993 11.5" stroke-width="0.5"/></svg></button>';
            let nextArrowHTML = '<button class="slick-next normal" aria-label="Next" type="button"><svg viewBox="0 0 7 12"><path d="M1.17588 11.5L6.10663 5.90972L1.07007 0.5" stroke-width="0.5"/></svg></button>';

            let $dots = $this.parent().hasClass('has_dots') ? true : false;
            let $arrows = $this.parent().hasClass('has_arrows') ? true : false;
            let $cursors = $this.parent().hasClass('has_cursors') ? true : false;
            let $dots_mobile = $this.parent().hasClass('has_dots_mobile') ? true : false;
            let $arrows_mobile = $this.parent().hasClass('has_arrows_mobile') ? true : false;
            let $cursors_mobile = $this.parent().hasClass('has_cursors_mobile') ? true : false;
            let animated_arrows = 'default';
            
            if ($this.parent().hasClass('has_animated_arrows')){
                animated_arrows = 'banners';
                prevArrowHTML = '<button class="slick-prev" aria-label="Previous" type="button"><span><svg viewBox="0 0 64 64"><path d="M44.797 17.28l0.003 29.44-25.6-14.72z" /></svg></span></button>';
                nextArrowHTML = '<button class="slick-next" aria-label="Next" type="button"><span><svg viewBox="0 0 64 64"><path d="M19.203 17.28l-0.003 29.44 25.6-14.72z" /></svg></span></button>';
            }


            let append_arrows = (typeof $this.attr('data-append-arrows') != "undefined" && $this.attr('data-append-arrows')) ? '.'+$this.attr('data-append-arrows') : $this;
            let append_text = (typeof $this.attr('data-append-text') != "undefined" && $this.attr('data-append-text')) ? '.'+$this.attr('data-append-text') : '';

            let $items_desk = typeof $this.attr('data-items-desk') != "undefined" ? parseInt($this.attr('data-items-desk')) : 1;
            let $items_desk2 = typeof $this.attr('data-items-desk2') != "undefined" ? parseInt($this.attr('data-items-desk2')) : 1;
            let $items_tablet = typeof $this.attr('data-items-tablet') != "undefined" ? parseInt($this.attr('data-items-tablet')) : 1;
            let $items_mobile = typeof $this.attr('data-items-mobile') != "undefined" ? parseInt($this.attr('data-items-mobile')) : 1;

            $this.on('init', function (event, slick, currentSlide, nextSlide) {   
                if ($this.parent().hasClass('animated') && $(window).width() > 960){
                    charming(document.querySelector('.slide-title'));
                }  
              
                toogleBanners(0, $this.find('.slick-current'), $this.parent().hasClass('animated'));
            });
            
            $this.slick({
                dots: $dots,
                prevArrow: prevArrowHTML,
                nextArrow: nextArrowHTML,
                slidesToShow: $items_desk,
                slidesToScroll: $items_desk,
                effectArrow: animated_arrows,
                arrows: $arrows,
                cursors: $cursors,
                appendArrows: $(append_arrows),
                appendText: $(append_text),
                infinite: false,
                adaptiveHeight: false,
                autoplay: true,
                autoplaySpeed: 8000,
                edgeFriction: 0,
                responsive: [
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: $items_desk2,
                            slidesToScroll: $items_desk2,
                        }
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            dots: $dots_mobile,
                            arrows: $arrows_mobile,
                            cursors: $cursors_mobile,
                            slidesToShow: $items_tablet,
                            slidesToScroll: $items_tablet,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            dots: $dots_mobile,
                            arrows: $arrows_mobile,
                            cursors: $cursors_mobile,
                            slidesToShow: $items_mobile,
                            slidesToScroll: $items_mobile,
                        }
                    }
                ]
            });
            $this.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                toogleBanners(1, $this.find('.slick-current'), $this.parent().hasClass('animated'));
            });
            $this.on('afterChange', function (event, slick, currentSlide, nextSlide) {
                toogleBanners(0, $this.find('.slick-current'), $this.parent().hasClass('animated'));
            });
        });

        function toogleBanners(type, $el, $animated) {
            if ($el.length > 0) {
                if ($animated && $(window).width()>960){
                    const delay = .1;
                    const time = ($el.find('.slide-title span').length * 0.05) + 0.4;

                    $el.find('.slide-title').toggleClass('banners-show');

                    $el.find('.slide-title span').each(function (i) {
                        TweenLite.to($(this), time, {
                            color: "#fff",
                            opacity: 1,
                            x: 0,
                            y: 0,
                            z: 0,
                            scale: 1,
                            delay: (delay * getRandom(1,7)),
                            ease: Power2.easeInOut
                        });
                    });
                }else{
                    const subtit = $el.find('.slide-subtitle');
                    const tit = $el.find('.slide-title');
                    const txt = $el.find('.slide-text');
                    const btn = $el.find('.slide-action');

                    $(subtit).toggleClass('banners-show');
                    $(tit).toggleClass('banners-show');
                    $(txt).toggleClass('banners-show');
                    $(btn).toggleClass('banners-show');
                }
            }
        }
    }
}
